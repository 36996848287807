var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AuthCard', [_c('Form', {
    ref: "loginForm",
    staticClass: "classic-form",
    attrs: {
      "model": _vm.loginForm,
      "rules": _vm.loginFormRules,
      "data-tests": "login-form"
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmitLogin.apply(null, arguments);
      }
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('login.username'),
      "prop": "username"
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('login.username'),
      "icon": "ios-mail-outline",
      "data-tests": "email-input"
    },
    model: {
      value: _vm.loginForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "username", $$v);
      },
      expression: "loginForm.username"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": _vm.$t('login.password'),
      "prop": "password"
    }
  }, [_c('Input', {
    attrs: {
      "type": _vm.showPassword ? 'text' : 'password',
      "placeholder": _vm.$t('login.password'),
      "data-tests": "password-input"
    },
    model: {
      value: _vm.loginForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }, [_c('Button', {
    attrs: {
      "slot": "append",
      "icon": "ios-eye-off-outline"
    },
    on: {
      "click": _vm.togglePassword
    },
    slot: "append"
  })], 1)], 1), _c('FormItem', [_c('Checkbox', {
    attrs: {
      "data-tests": "remember_me_checkbox"
    },
    model: {
      value: _vm.persistant,
      callback: function callback($$v) {
        _vm.persistant = $$v;
      },
      expression: "persistant"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.remember')) + " ")])], 1), _c('FormItem', {
    staticClass: "form-action-btn"
  }, [_c('button', {
    staticClass: "btn--elevated ivu-btn ivu-btn-primary ivu-btn-long ivu-btn-large",
    attrs: {
      "type": "submit",
      "value": "Submit",
      "loading": _vm.isLoading,
      "data-tests": "submit-button"
    }
  }, [!_vm.isLoading ? _c('span', [_vm._v(_vm._s(_vm.$t('login.login')))]) : _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  })], 1)], 1)])], 1), _c('div', {
    staticClass: "alternate-btn",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('router-link', {
    staticClass: "clickable-el heavy",
    attrs: {
      "tag": "a",
      "to": "/auth/forgot-password"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('login.forgotPassword')) + " "), _c('i', {
    staticClass: "fa fa-gear"
  })])], 1), _c('Collapse', {
    attrs: {
      "simple": ""
    }
  }, [_c('Panel', {
    attrs: {
      "name": "privacy_policy"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm._v(" Didomi, of which Agnostik is a part of, is the data controller of your personal data and collects your email address, surname and first name for authentication and identification purposes on the Facettes application on the legal basis of the execution of the contract linking you to Didomi. "), _c('br'), _c('br'), _vm._v(" Technical data (such as IP address or user ID) is also collected during your connection for maintenance and security purposes on the legal basis of Didomi's legitimate interest in ensuring the security of information and maintenance of the Facettes application. "), _c('br'), _c('br'), _vm._v(" You have the right to access your data, to rectify it, to request its deletion or to request the limitation of processing by writing to dpo@didomi.io "), _c('br'), _c('br')];
      },
      proxy: true
    }])
  }, [_c('h6', [_vm._v("Legal Notice")])])], 1), _c('small', {
    staticClass: "chips",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_vm._v(_vm._s(_vm.projectVersion))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }