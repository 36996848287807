<template>
  <AuthCard>
    <Form
      class="classic-form"
      ref="loginForm"
      @submit.native.prevent="handleSubmitLogin"
      :model="loginForm"
      :rules="loginFormRules"
      data-tests="login-form"
    >
      <FormItem
        :label="$t('login.username')"
        prop="username"
      >
        <Input
          type="email"
          :placeholder="$t('login.username')"
          icon="ios-mail-outline"
          v-model="loginForm.username"
          data-tests="email-input"
        />
      </FormItem>
      <FormItem
        :label="$t('login.password')"
        prop="password"
      >
        <Input
          :type="showPassword ? 'text': 'password'"
          :placeholder="$t('login.password')"
          v-model="loginForm.password"
          data-tests="password-input"
        >
          <Button
            slot="append"
            icon="ios-eye-off-outline"
            @click="togglePassword"
          />
        </Input>
      </FormItem>
      <FormItem>
        <Checkbox
          v-model="persistant"
          data-tests="remember_me_checkbox"
        >
          {{ $t('login.remember') }}
        </Checkbox>
      </FormItem>
      <FormItem class="form-action-btn">
        <button
          type="submit"
          value="Submit"
          :loading="isLoading"
          class="btn--elevated ivu-btn ivu-btn-primary ivu-btn-long ivu-btn-large"
          data-tests="submit-button"
        >
          <span v-if="!isLoading">{{ $t('login.login') }}</span>
          <Spin fix
                v-else
          >
            <Icon type="ios-loading"
                  size="18"
                  class="demo-spin-icon-load"
            />
          </Spin>
        </button>
      </FormItem>
    </Form>

    <div
      class="alternate-btn"
      style="margin-bottom:10px"
    >
      <router-link
        tag="a"
        to="/auth/forgot-password"
        class="clickable-el heavy"
      >
        {{ $t('login.forgotPassword') }}
        <i class="fa fa-gear" />
      </router-link>
    </div>

    <Collapse simple>
      <Panel name="privacy_policy">
        <h6>Legal Notice</h6>
        <template #content>
          Didomi, of which Agnostik is a part of, is the data controller of your personal data and collects your email
          address, surname and first name for authentication and identification purposes on the Facettes application on
          the legal basis of the execution of the contract linking you to Didomi.
          <br><br>
          Technical data (such as IP address or
          user ID) is also collected during your connection for maintenance and security purposes on the legal basis of
          Didomi's legitimate interest in ensuring the security of information and maintenance of the Facettes
          application.
          <br><br>
          You have the right to access your data, to rectify it, to request its deletion or to request the
          limitation of processing by writing to dpo@didomi.io
          <br><br>
          <!--
          <h6>Cookies policy</h6>
          By login in, you accept the storage of essential information on your browser. These 'cookies' are critical to the cor
          <br/><br/>
          <table>
            <tr>
              <td>fa7-token</td>
              <td>handle authentication</td>
            </tr>
            <tr>
              <td>fa7-user-persistent</td>
              <td>handle automatic reauthentication</td>
            </tr>
            <tr>
              <td>fa7-properties</td>
              <td>handle the list of available properties</td>
            </tr>
            <tr>
              <td>fa7-selectedProperty</td>
              <td>handle the selected property</td>
            </tr>
          </table>
          -->
        </template>
      </Panel>
    </Collapse>

    <small
      class="chips"
      slot="footer"
    >{{ projectVersion }}</small>
  </AuthCard>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AuthCard from '@/views/Login/AuthCard'
import {storageItemsNames} from '../../store/storageItems'

export default {
  name: 'AuthLogin',
  components: {
    AuthCard
  },
  props: {
    /**
     * Route name to redirect upon success
     */
    redirect: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      projectVersion: process.env.VUE_APP_VERSION,
      showPassword: false,
      isLoading: false,
      persistant: !!localStorage.getItem('fa7-user-persistant'),
      loginForm: {
        password: '',
        username: ''
      },
      resetPasswordForm: {
        email: ''
      },
      resetPasswordRules: {
        email: [
          {required: true, trigger: 'blur'},
          {type: 'email', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      properties: 'scope/properties',
      redirectAfterLogin: 'user/redirectAfterLogin'
    }),
    loginFormRules () {
      return {
        username: [
          {required: true, message: this.t('login.errUsrMissing'), trigger: 'blur'},
          {type: 'email', message: this.t('login.errUsrInvalid'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.t('login.errPwdMissing'), trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      authWithCredentials: 'user/authWithCredentials',
      setSelectedProperty: 'scope/setSelectedProperty',
      setSelectedDaterange: 'scope/setSelectedDaterange'
    }),
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    handleSubmitLogin (formName) {
      this.isLoading = true
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          return this.authWithCredentials({credentials: this.loginForm, persistant: this.persistant})
            .then(resp => {
              this.isLoading = false

              // TODO: handle redirection to old nav when logged in
              // const route = this.$route;
              // const routeQuery = this.$route.query.query
              // if (routeQuery && Object.values(routeQuery).length) {
              //   const params = JSON.parse(window.atob(routeQuery.param))
              //   const property = this.properties.find(property => property.id === parseInt(params.property_id))
              //   this.setSelectedDaterange(params.daterange)
              //   this.setSelectedProperty(property)
              //   this.$router.push(route.query.fullPath)
              // }
              const path = this.redirectAfterLogin
              if (path) {
                const ppath = (' ' + path).slice(1)
                this.$store.state.user.redirectAfterLogin = false
                return this.$router.push(ppath)
              }
              this.$router.push('/')
            })
            .catch(e => {
              this.isLoading = false
              console.log('error:', e)
              this.$Message.error('Invalid credentials')
            })
        } else {
          this.$Message.error('Invalid form')
          console.error('Form invalid')
        }
      })
    }
  },
  mounted () {
    if (this.username && this.remember) {
      this.loginForm.username = this.username
    }
  }
}
</script>
